import { useI18n } from 'vue-i18n'
import validator from 'validator' // https://www.npmjs.com/package/validator
import { isEmpty, isEmptyArray, isNullOrUndefined } from 'dvn-js-utils'

/**
 * Validator should return :
 * true when validation is succesfull
 * string containing error message when validation fails
 */

export type ValidationRule = (value: any) => string | boolean

export const useValidators = () => {
  const { t } = useI18n() // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables

  // 👉 Required Validator
  const requiredValidator = (value: unknown) => {
    if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
      return t('validators.requiredValidator')
    return !!String(value).trim().length || t('validators.requiredValidator')
  }

  // 👉 Email Validator
  const emailValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (Array.isArray(value))
      return value.every((val) => re.test(String(val))) || t('validators.emailValidator')
    return re.test(String(value)) || t('validators.emailValidator')
  }

  // 👉 New Email Validator
  const newEmailValidator = (newEmail: string, oldEmail: string) => {
    return newEmail !== oldEmail || t('validators.newEmailValidator')
  }

  // 👉 Username Validator
  const usernameValidator = (value: unknown) => {
    if (isEmpty(value)) return true

    const re = /^[a-zA-Z0-9]+$/
    if (Array.isArray(value))
      return value.every((val) => re.test(String(val))) || t('validators.usernameValidator')
    return re.test(String(value)) || t('validators.usernameValidator')
  }

  // 👉 Fullname Validator
  const fullnameValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    const valueAsString = String(value)
    return /^[0-9A-Z_-\s]*$/i.test(valueAsString) || t('validators.alphaDashValidator')
  }

  // 👉 Password Validator
  const passwordValidator = (password: string) => {
    // const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
    // const validPassword = regExp.test(password)

    const validPassword = validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    })

    return validPassword || t('validators.passwordValidator')
  }

  // 👉 Confirm Password Validator
  const confirmPasswordValidator = (value: string, target: string) => {
    return value === target || t('validators.confirmPasswordValidator')
  }

  // 👉 New Password Validator
  const newPasswordValidator = (newPassword: string, oldPassword: string) => {
    return newPassword !== oldPassword || t('validators.newPasswordValidator')
  }

  // 👉 Between Validator
  const betweenValidator = (value: unknown, min: number, max: number) => {
    const valueAsNumber = Number(value)
    return (
      (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) ||
      `${t('validators.betweenValidator.(1)')} ${min} ${t(
        'validators.betweenValidator.(2)',
      )} ${max}`
    )
  }

  // 👉 Integer Validator
  const integerValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    if (Array.isArray(value))
      return (
        value.every((val) => /^-?[0-9]+$/.test(String(val))) || t('validators.integerValidator')
      )
    return /^-?[0-9]+$/.test(String(value)) || t('validators.integerValidator')
  }

  // 👉 Regex Validator
  const regexValidator = (value: unknown, regex: RegExp | string): string | boolean => {
    if (isEmpty(value)) return true
    let regeX = regex
    if (typeof regeX === 'string') regeX = new RegExp(regeX)
    if (Array.isArray(value)) return value.every((val) => regexValidator(val, regeX))
    return regeX.test(String(value)) || t('validators.regexValidator')
  }

  // 👉 Alpha Validator
  const alphaValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    return /^[A-Z]*$/i.test(String(value)) || t('validators.alphaValidator')
  }

  // 👉 URL Validator
  const urlValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    return re.test(String(value)) || t('validators.urlValidator')
  }

  // 👉 Minimum Length Validator
  const minimumLengthValidator = (value: unknown, length: number) => {
    if (isEmpty(value)) return true
    return (
      String(value).length >= length ||
      `${t('validators.minimumLengthValidator.(1)')} ${length} ${t(
        'validators.minimumLengthValidator.(2)',
      )}`
    )
  }

  // 👉 Alpha-dash Validator
  const alphaDashValidator = (value: unknown) => {
    if (isEmpty(value)) return true
    const valueAsString = String(value)
    return /^[0-9A-Z_-]*$/i.test(valueAsString) || t('validators.alphaDashValidator')
  }

  return {
    requiredValidator,
    emailValidator,
    newEmailValidator,
    usernameValidator,
    fullnameValidator,
    passwordValidator,
    confirmPasswordValidator,
    newPasswordValidator,
    betweenValidator,
    integerValidator,
    regexValidator,
    alphaValidator,
    urlValidator,
    minimumLengthValidator,
    alphaDashValidator,
  }
}
